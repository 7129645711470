<template>
<div class="commercialTenant">
  <el-form :inline="true">
    <el-form-item>
      <el-select v-model="form.imeiStatus" clearable placeholder="请选择收款播报器">
        <el-option :value="1" label="已绑定"></el-option>
        <el-option :value="2" label="未绑定"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-select v-model="form.payeeStatus" clearable placeholder="请选择收款码">
        <el-option :value="1" label="已上传"></el-option>
        <el-option :value="2" label="未上传"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <myButton icon title="搜索" @myclick="currentChange(1)">
        <template slot="image">
          <img src="../../assets/image/btnicon/search.png" style="width:14px;height:14px" alt="">
        </template>
      </myButton>
    </el-form-item>
    <el-form-item>
      <myButton title="导出">
        <template slot="image">
          <img src="../../assets/image/btnicon/down.png" alt="">
        </template>
      </myButton>
    </el-form-item>
  </el-form>
  <el-table :data="tableData" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
    <el-table-column label="市场名称" prop="mktName"></el-table-column>
    <el-table-column label="商户姓名" prop="ctcpsnNm"></el-table-column>
    <el-table-column label="手机号码" prop="mblphNo"></el-table-column>
    <el-table-column label="商铺名称" prop="mktMrchNm"></el-table-column>
    <el-table-column label="商家编号" prop="mktMrchId"></el-table-column>
    <el-table-column label="证件号码" prop="crdtNo"></el-table-column>
    <el-table-column label="证件类型" prop="mrchCrdtTp"></el-table-column>
    <el-table-column label="操作类型" prop="oprTp"></el-table-column>
    <el-table-column label="收款播报器">
      <template slot-scope="scope">
        <span>{{scope.row.imeiId > 0 ? '已绑定' : '未绑定'}}</span>
      </template>
    </el-table-column>
    <el-table-column label="收款码">
      <template slot-scope="scope">
        {{scope.row.payeeId > 0 ? '已上传' : '未上传'}}
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <div class="slot-box">
          <pop tips="更新收款播报器" v-if="scope.row.imeiId > 0" @myclick="binding(scope.row)">
            <img class="icon" src="../../assets/image/icon/gengxingAudio.png" alt="">
          </pop>
          <pop tips="绑定收款播报器" v-else @myclick="binding(scope.row)">
            <img class="icon" src="../../assets/image/icon/bangdingAudio.png" />
          </pop>
          <pop tips="更新收款码" v-if="scope.row.payeeId > 0" @myclick="payeeCode(scope.row, 2)">
            <img class="icon" src="../../assets/image/icon/gengxingCode.png" />
          </pop>
          <pop tips="上传收款码" v-else @myclick="payeeCode(scope.row, 1)">
            <img class="icon" src="../../assets/image/icon/shangchuanCode.png" alt="">
          </pop>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="publicPage">
    <el-pagination next-text="下一页"  prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="form.pageSize" :current-page="form.page" @current-change="currentChange"></el-pagination>
  </div>
  <el-dialog title="新增/编辑收款播报器" :visible.sync="dialogVisible" width="880px" @close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
      <el-table :data="ruleForm.data" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
        <el-table-column label="设备名称" prop="deviceName">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].deviceName`" :rules="{ required: true, message: '请输入设备名称', trigger: 'blur'}">
              <el-input v-model="scope.row.deviceName" placeholder="请输入设备名称"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="设备IMEI码" prop="imei">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].imei`" :rules="{ required: true, message: '请输入设备IMEI码', trigger: 'blur'}">
              <el-input v-model="scope.row.imei" placeholder="请输入设备IMEI码"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="设备Key" prop="productKey">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].productKey`" :rules="{ required: true, message: '请输入设备Key', trigger: 'blur'}">
              <el-input v-model="scope.row.productKey" style="width: 100%" placeholder="请输入设备Key"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="设备Secret" prop="deviceSecret">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].deviceSecret`" :rules="{ required: true, message: '请输入设备Secret', trigger: 'blur'}">
              <el-input v-model="scope.row.deviceSecret" placeholder="请输入设备Secret"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <myButton @myclick="delInspection(scope.$index)">删除</myButton>
            <div class="" style="height: 20px;"></div>
          </template>
        </el-table-column>
      </el-table>
      <div style="marginTop: 15px;">
        <myButton @myclick="addInspection">添加</myButton>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <myButton @myclick="dialogVisible = false" right>取消</myButton>
      <myButton @myclick="confirm">确定</myButton>
    </span>
  </el-dialog>

  <el-dialog title="新增/编辑收款码" :visible.sync="codeVisible" width="880px" @close="close">
    <el-form :model="codeRuleForm" :rules="codeRules" ref="codeRuleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="收款码" prop="imei">
        <el-upload class="upload-demo" action="" accept="image/*" :show-file-list="false" :auto-upload="false" :on-change="handlePreview">
          <img :src="codeRuleForm.imei" class="imgBox" alt="" v-if="codeRuleForm.imei" />
          <el-button size="mini">上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <myButton @myclick="codeVisible = false" right>取消</myButton>
      <myButton @myclick="codeConfirm">确定</myButton>
    </span>
  </el-dialog>
</div>
</template>
<script>
export default {
  name: 'list',
  data() {
    return {
      form: {
        page: 1,
        pageSize: 10,
        mktId: '',
        payeeStatus: '',
        imeiStatus: ''
      },
      total: 0,
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        merchantId: '',
        data: []
      },
      rules: {
        imei: [
          { required: true, message: '请输入设备IMEI码', trigger: 'click' }
        ]
      },
      codeRuleForm: {
        merchantId: '',
        imei: ''
      },
      codeRules: {
        imei: [
          { required: true, message: '请上传收款码', trigger: 'click' }
        ]
      },
      codeVisible: false
    };
  },
  created() {
    this.form.mktId = this.$route.query.id;
    this.getList();
  },
  methods: {
    payeeCode(row, type) {
      if (type == 1) {
        this.codeRuleForm.merchantId = row.id;
        this.codeVisible = true;
      } else {
        this.$request.HttpGet('/admin/hsbMerchantPayee/find', {
          id: row.payeeId
        }).then(res => {
          this.codeRuleForm.id = row.payeeId;
          this.codeRuleForm.merchantId = row.id;
          this.codeRuleForm.imei = res.data.imei;
          this.codeVisible = true;
        });
      }
    },
    close() {
      this.codeRuleForm = {
        merchantId: '',
        imei: ''
      };
    },
    codeConfirm() {
      this.$refs['codeRuleForm'].validate((valid) => {
        if (valid) {
          let url = '';
          if (this.codeRuleForm.payeeId) {
            url = '/admin/hsbMerchantPayee/update';
          } else {
            url = '/admin/hsbMerchantPayee/create';
          }
          this.$request.HttpPost(url, this.codeRuleForm).then(res => {
            this.$plugin.notifySuccess(res.msg);
            this.codeVisible = false;
            this.getList();
          });
        }
      });
    },
    handlePreview(file) {
      if (file && file.raw) {
        let obj = new FormData();
        obj.append('file',file.raw);
        let url = URL.createObjectURL(file.raw);
        this.$request.HttpPost('/admin/upload/upload',obj).then(res => {
          this.codeRuleForm.imei = process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    getList() {
      this.$request.HttpGet('/admin/hsbMerchant/list', this.form).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    currentChange(page) {
      this.form.page = page;
      this.getList();
    },
    binding(row) {
      this.$request.HttpGet('/admin/hsbMerchantImei/list', {
        merchantId: row.id,
        page: 1,
        pageSize: 10000
      }).then(res => {
        this.ruleForm.merchantId = row.id;
        this.ruleForm.data = res.data.list || [];
        this.dialogVisible = true;
      });
    },
    addInspection() {
      this.ruleForm.data.push({
        imei: '',
        productKey: '',
        deviceSecret: '',
        deviceName: ''
      });
    },
    delInspection(index) {
      this.ruleForm.data.splice(index, 1);
    },
    handleClose() {
      this.ruleForm = {
        merchantId: '',
        data: []
      };
    },
    confirm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$request.HttpPost('/admin/hsbMerchantImei/create', this.ruleForm).then(res => {
            this.$plugin.notifySuccess(res.msg);
            this.dialogVisible = false;
            this.getList();
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
  .boxList {
    .list {
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      .el-button{
        margin-left: 10px;
      }
    }
  }
  .imgBox {
    width: 200px;
    display: block;
    margin-bottom: 10px;
  }
</style>
